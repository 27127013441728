<template>
  <div>
    <v-card
      flat
    >
      <v-card-text>
        <v-row>
          <v-col
            md="4"
            cols="12"
          ></v-col>
          <v-row>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="deposit_slips.date_of_deposit"
                label="Date Deposited"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>

            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="deposit_slips.time_of_deposit"
                label="Time Deposited"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="deposit_slips.bank_depositories.bank_code"
                label="Bank Code"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="deposit_slips.bank_depositories.account_no"
                label="Bank Account #"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="amount_formated"
                label="Amount"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="balance_formated"
                label="Balance"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table dense
                  :headers="headers"
                  :items="liquidations"
                  :search="search"
                  disable-pagination
                  hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Not Yet Deposited Data</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn color="success" class="mx-2" @click="add_additional_payment(1)">Add Transient
            Payment
          </v-btn>
          <v-btn color="info" class="mx-2" @click="add_additional_payment(2)">Add Addional Payment
          </v-btn>
          <v-text-field
            class="mx-2"
            :append-icon="icons.mdiAccountSearch"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>
            {{ item.members_information!=null?item.members_information.company_name:'' }}
          </td>
          <td>
            {{ item.members_information!=null?item.members_information.contact_no:'' }}
          </td>
          <td>
            {{ item.branch_information.branch_code }}
          </td>
          <td>
            {{ item.branch_data.room_no }}
          </td>
          <td>
            {{ formatPrice(item.amount )}}
          </td>
          <td>
            {{ item.remarks}}
          </td>
          <td>
            {{ item.due_date }}
          </td>
          <td class="text-center">
            <v-icon
              v-show="item.deposit_slip_id===null"
              v-if="!is_deleting"
              class="mr-2"
              color="success"
              @click="load_to_deposit(item)"
            >
              {{icons.mdiCheck}}
            </v-icon>
            <v-icon
              v-show="item.deposit_slip_id!=null"
              v-if="!is_deleting"
              class="mr-2"
              color="error"
              @click="remove_to_deposit(item)"
            >
              {{icons.mdiDelete}}
            </v-icon>
            <v-progress-circular color="info" indeterminate
                                 v-else></v-progress-circular>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="is_deleting" persistent max-width="80%">
      <v-form ref="form2" class="multi-col-validation">
        <v-card>
          <v-toolbar dense dark color="primary">
            <v-toolbar-title
            ><h4 class="font-weight-light">PAYMENT DETAILS</h4>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row class="mt-4">
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="or_date"
                  label="OR Date"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  type="date"
                ></v-text-field>
              </v-col>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="or_no"
                  label="OR #"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col
                md="12"
                cols="12"
              >
                <v-text-field
                  v-model="amount"
                  label="Amount"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-btn color="success" class="w-full mb-2" @click="save_payment"> Save Payment</v-btn>
          <v-btn color="error" class="w-full" @click="is_deleting = false"> Close</v-btn>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="is_add_transient" max-width="80%">
      <v-form ref="form2" class="multi-col-validation">
        <v-card>
          <v-card-text>
            <v-layout row wrap class="align-center mx-2 mt-5">
              <v-flex xs12 md6>
                <v-select
                  class="mx-2"
                  v-model="branch_id"
                  dense
                  outlined
                  label="Branch"
                  item-value="id"
                  item-text="branch_code"
                  :items="branch_items"
                  :rules="rules.combobox_rule"
                  @change="selected_branches"
                ></v-select>
              </v-flex>
              <v-flex xs12 md6>
                <v-select
                  class="mx-2"
                  v-model="room_id"
                  dense
                  outlined
                  label="Room #"
                  item-value="id"
                  item-text="room_no"
                  :items="room_items"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-flex>

              <v-flex xs12 md4>
                <v-text-field
                  class="mx-2"
                  v-model="or_date"
                  label="OR Date"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  type="date"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-text-field
                  class="mx-2"
                  v-model="or_no"
                  label="OR #"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-text-field
                  v-model="amount"
                  label="Amount"
                  class="mx-2"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  required
                  dense
                  outlined
                  type="number"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12 v-if="is_add_payment">
                <v-select
                  class="mx-2"
                  v-model="type_of_payment"
                  dense
                  outlined
                  label="Type of Payment"
                  :items="['ELECTRIC BILL','WATER BILL','INTERNET BILL','PENALTY FOR LATE PAYMENTS']"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-btn color="success" class="w-full mb-2" @click="add_transient"> Save Payment</v-btn>
          <v-btn color="error" class="w-full" @click="is_add_transient = false"> Close</v-btn>
        </v-card>
      </v-form>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiCheck, mdiDelete} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {

      is_add_transient: false,
      is_transient: false,
      is_add_payment: false,
      is_deleting: false,
      selected_data: {},

      or_date: '',
      or_no: '',
      amount: '',
      type_of_payment: 'TRANSIENT',

      branch_id: '',
      room_id: '',
      branch_items: [],
      room_items: [],

      search: '',
      headers: [
        {text: 'Name', value: 'members_information.company_name', sortable: true},
        {text: 'Contact #', value: 'members_information.contact_no', sortable: true},
        {text: 'Building', value: 'members.email_address', sortable: true},
        {text: 'Room #', value: 'members.contact_no', sortable: true},
        {text: 'Amount', value: 'amount', sortable: true},
        {text: 'Type', value: 'amount', sortable: true},
        {text: 'Due Date', value: 'year_of', sortable: true},
        {text: 'Payment', align: 'center', value: 'view_owners', sortable: true},
      ],
    };
  };
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiCheck,
          mdiDelete,
        },
      }
    },
    data() {
      return initialState()
    },
    props: {
      balance_formated: String,
      amount_formated: String,
      deposit_slips: Object,
      liquidations: Array,
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('branch_information', ['list_of_branch']),
      reset() {
        Object.assign(this.$data, initialState())
        this.initialize_data()
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      load_to_deposit(item) {
        this.selected_data = item
        this.amount = this.formatPrice(item.amount)
        this.is_deleting = true
      },
      add_additional_payment(value) {
        this.room_id = ''
        this.branch_id = ''
        this.is_add_transient = true
        this.is_transient = value === 1
        this.is_add_payment = value === 2
        this.list_of_branch({
          is_active: 1
        })
          .then(response => {
            this.branch_items = response.data
          })
      },
      selected_branches(value) {
        var index = this.branch_items
          .map(function (x) {
            return x.id
          })
          .indexOf(value)
        if (index != -1) {
          this.room_id = ''
          this.room_items = this.branch_items[index].rooms
        }
      },
      save_payment() {
        if (this.$refs.form2.validate()) {
          this.$emit('data', {
            balance: parseFloat(this.deposit_slips.balance) - parseFloat(this.selected_data.amount),
            id: this.selected_data.id,
            or_no: this.or_no,
            or_date: this.or_date,
            deposit_slip_id: this.deposit_slips.id,
          })
          this.is_deleting = false
        }
      },
      remove_to_deposit(item) {
        this.$emit('remove', {
          id: item.id,
        })
        this.is_deleting = false
      },
      add_transient() {
        if (this.$refs.form2.validate()) {
          this.$emit('transient_data', {
            balance: parseFloat(this.deposit_slips.balance) - parseFloat(this.amount),
            or_no: this.or_no,
            or_date: this.or_date,
            amount: this.amount,
            type_of_payment: this.type_of_payment,
            branch_id: this.branch_id,
            branch_data_id: this.room_id,
            deposit_slip_id: this.deposit_slips.id,
          })
          this.is_add_transient = false
        }
      },
    }
  }
</script>
